<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <!--<div><router-link to="/Admin/kecsc/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>-->
                    <div><el-input v-model="where.F_VR_XINGM" placeholder="请输入学员姓名"></el-input></div>
                    <div style="width:180px;">
                        <el-select v-model="where.F_TI_WEIJTSFSH" placeholder="选择截图审核类型">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="待审核" value="2"></el-option>
                            <el-option label="已通过" value="1"></el-option>
                            <el-option label="已拒绝" value="3"></el-option>
                        </el-select>
                    </div>
                    <div style="width:180px;">
                        <el-select v-model="where.F_TI_WEIKTSFSC" placeholder="选择截图上传类型">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="已上传" value="1"></el-option>
                            <el-option label="未上传" value="2"></el-option>
                        </el-select>
                    </div>
                    <div><el-button icon="el-icon-search" @click="get_adv_list()">条件筛选</el-button></div>
                </div>

                <!--<div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>-->
            </div>
            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="F_IN_ID" label="#"  width="50px"></el-table-column>
                    <el-table-column label="学员姓名" width="75px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dd class="table_dl_dd_all">{{ scope.row.F_VR_XINGM }}<!-- -{{ scope.row.F_VR_JIANHRXM }}--></dd>
                            </dl>
                        </template>
                    </el-table-column>
                    <!--<el-table-column prop="F_VR_XINGM" label="学员姓名" width="70px"></el-table-column>-->
                    <el-table-column prop="F_VR_XUEJH" label="学籍号" width="65px"></el-table-column>
                    <!--<el-table-column prop="F_VR_JIDMC" label="基地" width="80px"></el-table-column>-->
                    <el-table-column prop="F_IN_NAME" label="营期" width="100px"></el-table-column>
                    <el-table-column label="营期期限" width="85px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <!-- <dt><img :src="scope.row.F_VR_XIANGCFM||require('@/assets/default_pic.png')" width="50px" height="50px"></dt>-->
                                <dd class="table_dl_dd_all">{{ scope.row.F_VR_QISHU }}</dd>
                                <dd class="table_dl_dd_all">{{ scope.row.F_VR_QISHI }}</dd>
                                <!--<dd class="table_dl_dd_all">至</dd>-->
                                <dd class="table_dl_dd_all">{{ scope.row.F_VR_JIEZHI }}</dd>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column label="定金" width="105px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_DINGJZT==1">已支付{{ scope.row.F_VR_DINGJ }}</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_DINGJZT==2">待支付{{ scope.row.F_VR_DINGJ }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="尾款" width="105px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_WEIKZT==1">已支付{{ scope.row.F_VR_WEIK }}</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_WEIKZT==2">待支付{{ scope.row.F_VR_WEIK }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="F_VR_ZSLS_XINGM" label="招生老师" width="70px"></el-table-column>

                    <!--<el-table-column label="尾款截图">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><img :src="scope.row.F_TE_WEIKT||require('@/assets/default_pic.png')" width="50px" height="50px"></dt>
                                <dd class="table_dl_dd_all">{{ scope.row.adv_title }}</dd>
                            </dl>
                        </template>
                    </el-table-column>-->

                    <el-table-column label="尾款截图" align="center" width="185px">
                        <template slot-scope="scope">
                            <span v-for="(item,index) in scope.row.F_TE_WEIKT" :key="index">
                                <el-popover placement="left" trigger="click" width="600">
                                    <img :src="item" width="100%" />
                                    <img
                                            slot="reference"
                                            :src="item"
                                            :alt="item"
                                            style="max-height: 70px;max-width: 70px; padding: 5px"
                                    />
                                </el-popover>
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column label="截图是否上传" width="80px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_WEIKTSFSC==1">已上传</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_WEIKTSFSC==2">未上传</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="截图是否审核" width="100px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_WEIJTSFSH==1">已审核</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_WEIJTSFSH==2">未审核</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_WEIJTSFSH==3">审核未通过</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column prop="F_IN_CHUANGJSJ" label="截图上传时间">
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_IN_WEIKJTSCSJ<=0"> - </div>
                            <div v-else>{{scope.row.F_IN_WEIKJTSCSJ|formatDate}}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" fixed="right" width="180px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'weik_edit',params:{id:scope.row.F_IN_ID}})">编辑</el-button>
                            <el-button icon="el-icon-edit" :disabled="scope.row.goods_verify==1 || scope.row.goods_verify==2" @click="verify_click(scope.row.F_IN_ID)">审核</el-button>
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          adv_position_id:0,
          where:{
              /*F_VR_XINGM:'',
              F_TI_WEIJTSFSH:'',
              F_TI_WEIKTSFSC:''*/
          },
      };
    },
    watch: {},
    computed: {},
    methods: {
        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.F_IN_ID);
            });
            this.select_id = ids.join(',');
        },
        get_adv_list:function(){
            this.where.page = this.current_page;
            this.$get(this.$api.getWeiklb,this.where).then(res=>{
                this.page_size = res.data.per_page;
                this.total_data = res.data.total;
                this.current_page = res.data.current_page;
                this.list = res.data.data;
            })

        },
        // 删除处理
        del:function(F_IN_ID){
            if(this.$isEmpty(F_IN_ID)){
                return this.$message.error('请先选择删除的对象');
            }
            this.$post(this.$api.delKecsc,{F_IN_ID:F_IN_ID}).then(res=>{
                if(res.code == 200){
                    this.get_adv_list();
                    return this.$message.success("删除成功");
                }else{
                    return this.$message.error("删除失败");
                }
            });
        },
        current_change:function(e){
            this.current_page = e;
            this.get_adv_list();
        },
        verify_click:function(F_IN_ID){
            this.$confirm('此操作将控制尾款截图是否通过审核, 是否继续?', '提示', {
                confirmButtonText: '同意',
                cancelButtonText: '拒绝',
                type: 'info'
            }).then(() => {
                this.$post(this.$api.weikVerifyChange,{F_IN_ID:F_IN_ID,F_TI_WEIJTSFSH:1}).then(()=>{
                    this.$message.success('成功审核');
                    this.get_adv_list();
                });

            }).catch(() => {
                this.$post(this.$api.weikVerifyChange,{F_IN_ID:F_IN_ID,F_TI_WEIJTSFSH:3}).then(()=>{
                    this.$message.info('已拒绝');
                    this.get_adv_list();
                });

            });
        },
    },
    created() {
        this.get_adv_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
</style>